<template>
  <div>
    <div class="editable-cell">
      <div
        v-if="editable"
        class="editable-cell__input-wrapper"
      >
        <a-input
          v-if="props.inputHtmlType === 'text'"
          ref="inputRef"
          :value="currentValue"
          @change="handleChange"
          @pressEnter="check"
        />
        <a-input-number
          v-else-if="props.inputHtmlType === 'number'"
          ref="inputRef"
          :value="currentValue"
          @change="handleChange"
          @pressEnter="check"
        />
        <a-icon
          v-show="editable"
          type="check"
          class="editable-cell__submit-icon"
          @click="check"
        />
      </div>
      <div
        v-else
        class="editable-cell__text-wrapper"
      >
        <span
          v-if="props.error"
          class="text-error"
        >
          {{ props.text || " " }}
        </span>
        <span v-else-if="props.inputHtmlType === 'number'">
          {{ props.text || 0 }}
        </span>
        <span v-else>
          {{ props.text || " " }}
        </span>
        <a-icon
          type="edit"
          class="editable-cell__edit-icon"
          @click="handleEdit()"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { nextTick, ref } from "vue"

const props = defineProps({
  text: {
    type: String | Number,
    default: ""
  },
  inputHtmlType: {
    type: String,
    default: "text"
  },
  error: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(["change"])

const inputRef = ref(null)

const currentValue = ref(props.text)
const editable = ref(false)

const handleChange = (e) => {
  currentValue.value = props.inputHtmlType === "number" ? e : e.target.value
}

const check = () => {
  emit("change", currentValue.value, closeEdit)
}
const handleEdit = () => {
  editable.value = true
  nextTick(inputRef.value?.focus)
}
const closeEdit = () => {
  editable.value = false
}
</script>

<style lang="scss" scoped>
.editable-cell {
  $root: &;
  position: relative;

  &__input-wrapper,
  &__text-wrapper {
    padding-right: 24px;
    min-height: 31px;
  }

  &__text-wrapper {
    padding: 5px 24px 5px 5px;
    // color: #108ee9;
  }

  &__edit-icon,
  &__submit-icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(calc(-50% - 1px));
    width: 20px;
    cursor: pointer;
  }

  &__edit-icon {
    // display: none;

    &:hover {
      color: #108ee9;
    }
  }

  &__submit-icon {
    &:hover {
      color: #108ee9;
    }
  }

  &:hover {
    #{$root}__edit-icon {
      display: inline-block;
    }
  }
}

.text-error {
  color: $red-color;
}
</style>
