<template>
  <div class="login-nav">
    <div
      v-if="isLogged"
      class="profile"
    >
      <div class="profile__username">
        {{ profile.username }}
      </div>
      <a-button
        class="profile__logout"
        size="small"
        @click="logout(false)"
      >
        {{ $t("signOut") }}
      </a-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"

export default {
  name: "LoginNav",
  computed: {
    ...mapState("Auth", {
      profile: (state) => state.profile,
      isLogged: (state) => state.isLogged
    })
  },
  methods: {
    ...mapActions("Auth", ["logout"])
  }
}
</script>

<style lang="scss" scoped>
.login-nav {
  // margin-left: auto;
  height: 100%;
  display: flex;
  cursor: default;
  justify-content: flex-end;
  border-left: solid 1px #f1f1f1;
  padding-left: 24px;
}

.profile {
  display: flex;
  align-items: center;
  justify-content: center;

  &__logout {
    margin-left: 12px;
  }
}
</style>
