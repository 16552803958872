<template>
  <div>
    <PageHeader
      :title="$t('placedOrders')"
      :showLanguageSelect="false"
    >
      <template #filters>
        <a-input-search
          v-model="filteredInfo.search"
          :placeholder="$t('search')"
          allowClear
          @search="handleTableFilterChange"
        >
          <template #enterButton>
            <a-button icon="search" />
          </template>
        </a-input-search>

        <a-input-search
          v-model="filteredInfo.seller__name"
          :placeholder="$t('sellerName')"
          allowClear
          @search="handleTableFilterChange"
        >
          <template #enterButton>
            <a-button icon="search" />
          </template>
        </a-input-search>

        <a-input-search
          v-model="filteredInfo.seller__slug"
          placeholder="Slug"
          allowClear
          @search="handleTableFilterChange"
        >
          <template #enterButton>
            <a-button icon="search" />
          </template>
        </a-input-search>

        <a-input-search
          v-model="filteredInfo.customer_full_name"
          :placeholder="$t('customerFilterPlaceholder')"
          allowClear
          @search="handleTableFilterChange"
        >
          <template #enterButton>
            <a-button icon="search" />
          </template>
        </a-input-search>

        <a-input-search
          v-model="filteredInfo.customer_mobile_phone"
          :placeholder="$t('customerPhoneNumber')"
          allowClear
          @search="handleTableFilterChange"
        >
          <template #enterButton>
            <a-button icon="search" />
          </template>
        </a-input-search>

        <a-input-search
          v-model="filteredInfo.code"
          :placeholder="$t('orderCode')"
          allowClear
          @search="handleTableFilterChange"
        >
          <template #enterButton>
            <a-button icon="search" />
          </template>
        </a-input-search>

        <a-input-search
          v-model="filteredInfo.ttn"
          :placeholder="$t('ttn')"
          allowClear
          @search="handleTableFilterChange"
        >
          <template #enterButton>
            <a-button icon="search" />
          </template>
        </a-input-search>

        <a-select
          v-model="filteredInfo.status"
          :placeholder="$t('orderStatus')"
          mode="multiple"
          allowClear
          @change="handleTableFilterChange"
        >
          <a-select-option
            v-for="status in orderStatuses"
            :key="status.value"
          >
            <div :style="{ color: status.color }">{{ status.title }}</div>
          </a-select-option>
        </a-select>

        <a-select
          v-model="filteredInfo.payment_status"
          :placeholder="$t('paymentStatus')"
          mode="multiple"
          allowClear
          @change="handleTableFilterChange"
        >
          <a-select-option
            v-for="status in paymentStatuses"
            :key="status.value"
          >
            {{ status.title }}
          </a-select-option>
        </a-select>

        <a-spin :spinning="isMetaFetching">
          <a-select
            v-model="filteredInfo.reject_reason"
            :placeholder="$t('rejectReason')"
            mode="multiple"
            allowClear
            style="width: 100%"
            @change="handleTableFilterChange"
          >
            <a-select-option
              v-for="reason in ordersMeta.reject_reasons"
              :value="`${reason.id}`"
              :key="reason.id"
            >
              {{ reason.reason.uk }}
            </a-select-option>
          </a-select>
        </a-spin>

        <a-select
          :placeholder="$t('filtersCollection')"
          allowClear
          :options="filtersCollectionOptions"
          @change="(value) => handleTableFilterChangeCollection(value)"
        />

        <a-form-item :help="$t('creatingDate')">
          <a-range-picker
            v-model="filteredInfo.created_at"
            format="DD.MM.YYYY"
            valueFormat="YYYY-MM-DD"
            @change="handleTableFilterChange"
          />
        </a-form-item>

        <a-form-item :help="$t('updatedAt')">
          <a-range-picker
            v-model="filteredInfo.updated_at"
            format="DD.MM.YYYY"
            valueFormat="YYYY-MM-DD"
            @change="handleTableFilterChange"
          />
        </a-form-item>
      </template>

      <template #actions>
        <a-form-item>
          <a-button
            @click="handleTableFiltersReset()"
            icon="undo"
          >
            {{ $t("clearFilters") }}
          </a-button>
        </a-form-item>

        <a-form-item>
          <a-button
            @click="onTableChange()"
            icon="sync"
            type="primary"
            :loading="isFetching"
          >
            {{ $t("update") }}
          </a-button>
        </a-form-item>
      </template>
    </PageHeader>

    <!-- Table START -->
    <div class="table-wrapper">
      <a-table
        :scroll="{ x: true }"
        :columns="columns"
        :data-source="dataSource"
        :loading="isFetching"
        :pagination="paginationInfo"
        row-key="id"
        @change="onTableChange"
      >
        <template #dateRenderer="text">
          <TableRendererDateTime :date="text" />
        </template>

        <template #sellerRenderer="text, record">
          <div>{{ record.seller.name }}</div>
          <div :style="{ opacity: '0.45' }">{{ record.seller.slug }}</div>
        </template>

        <template #customerRenderer="text, record">
          <div :style="{ display: 'flex' }">
            <!-- If customer not registered show receiver mobile phone -->
            <div>
              +{{
                record.customer.is_active
                  ? record.customer.mobile_phone
                  : record.receiver_mobile_phone
              }}
            </div>
            <a-popover
              v-if="
                record.customer.is_active &&
                record.customer.mobile_phone !== record.receiver_mobile_phone
              "
              :style="{ marginLeft: 'auto' }"
            >
              <template slot="content">
                <b>{{ $t("receiverTitle") }}:</b>
                <div>+{{ record.receiver_mobile_phone }}</div>
                <div>
                  {{ record.receiver_first_name }} {{ record.receiver_last_name }}
                  {{ record.receiver_middle_name }}
                </div>
              </template>

              <a-icon
                type="user-add"
                :style="{ color: '#1890FF', marginTop: '2px' }"
              />
            </a-popover>
          </div>
          <div :style="{ opacity: '0.45' }">
            {{ record.customer.first_name }} {{ record.customer.last_name }}
          </div>
        </template>

        <template #statusRenderer="text, record">
          <div :style="{ display: 'flex', alignItems: 'center' }">
            <div :style="{ color: orderStatuses[text].color }">
              {{ orderStatuses[text].title }}
            </div>
            <a-popover
              v-if="record.has_notes"
              :style="{ marginLeft: 'auto' }"
            >
              <template slot="content">
                <a-spin :spinning="orderNotesFetching">
                  <OrderNotes
                    :items="orderNotes"
                    :order-status="record.status"
                    :reject-reason="record.reject_reason?.reason.uk"
                  />
                </a-spin>
              </template>

              <a-icon
                type="info-circle"
                :style="{ color: '#1890FF', marginTop: '2px' }"
                @mouseenter="getOrderNotes(record.code)"
              />
            </a-popover>
          </div>
        </template>

        <template #ttnRenderer="text">
          <a
            v-if="getDeliveryOperator(text) === 'NOVA_POST'"
            :href="`https://novaposhta.ua/tracking/?cargo_number=${text}`"
            target="_blank"
          >
            {{ text }}
          </a>
          <a
            v-else-if="getDeliveryOperator(text) === 'UKR_POST'"
            :href="`https://track.ukrposhta.ua/tracking_UA.html?barcode=${text}`"
            target="_blank"
          >
            {{ text }}
          </a>
          <template v-else>
            {{ text }}
          </template>
        </template>

        <template #paymentRenderer="text, record">
          <div
            v-if="text"
            :style="{ color: paymentStatuses[text].color }"
          >
            {{ paymentStatuses[text].title }}
          </div>
          <div
            v-if="record.payment_method"
            :style="{
              opacity: '0.45',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              width: '180px'
            }"
          >
            {{ record.payment_method.name.uk }}
          </div>
        </template>

        <template
          slot="actions"
          slot-scope="text, record"
        >
          <a-button
            type="dashed"
            shape="circle"
            icon="edit"
            @click="handleOpenEditModal(record)"
          />
        </template>
      </a-table>
    </div>
    <!-- Table END -->

    <EditOrderModal
      :is-opened="editModalIsOpened"
      :orderCode="editableOrderCode"
      @close="handleCloseEditModal"
      @onUpdate="onUpdateOrder"
    />
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue"
import i18n from "@/i18n"

import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime"
import { EditOrderModal, OrderNotes } from "./components/EditOrderModal"
import PageHeader from "@/ant-components/PageHeader"

import {
  fetchOrders,
  fetchOrdersMeta,
  fetchOrderNotes
} from "@/modules/MPAdmin/services/ordersService.js"

import { useStatuses } from "@/composables/useStatuses.js"
import useAntTableQuery from "@/composables/useAntTableQuery.js"
import useOrdersColumns from "./useOrdersColumns.js"

// metaInfo: {
//   title: "Замовлення"
// },

const { orderStatuses, paymentStatuses } = useStatuses()

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange,
  handleTableFiltersReset,
  updateTableDataRecord
} = useAntTableQuery({
  queryFunction: fetchOrders,
  requestParams: { changeRouterQuery: true }
})
const { ordersListColumns: columns } = useOrdersColumns({
  sortedInfo: computed(() => sortedInfo.value)
})

const editModalIsOpened = ref(false)
const editableOrderCode = ref(null)
const orderNotes = ref([])
const orderNotesFetching = ref(false)
const ordersMeta = ref({})
const isMetaFetching = ref(false)

const filtersCollectionOptions = computed(() => [
  { value: "NEW", label: i18n.t("filtersCollectionOverdueNew") },
  { value: "IN_PROCESSING", label: i18n.t("filtersCollectionOverdueInProcessing") },
  { value: "SELLER_APPROVED", label: i18n.t("filtersCollectionOverdueInApproved") },
  { value: "SHIPPED", label: i18n.t("filtersCollectionOverdueInShipped") }
])

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}

const getMeta = async () => {
  try {
    isMetaFetching.value = true

    const { data } = await fetchOrdersMeta()

    ordersMeta.value = data
    isMetaFetching.value = false
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      isMetaFetching.value = false
      return
    }

    setTimeout(getMeta, 2500)
  }
}

const onUpdateOrder = (payload) => {
  updateTableDataRecord({ payload, identifier: "id" })
}

const getOrderNotes = async (orderCode) => {
  orderNotesFetching.value = true
  orderNotes.value = []

  try {
    const { data } = await fetchOrderNotes(orderCode)

    orderNotes.value = data.results
  } catch {
    orderNotes.value = []
  } finally {
    orderNotesFetching.value = false
  }
}

const getDeliveryOperator = (value) => {
  if (value.match(/^\w{13}$/g)) {
    return "UKR_POST"
  }
  if (value.match(/^(20|59)\d{12}$/g)) {
    return "NOVA_POST"
  }

  return ""
}

const handleOpenEditModal = (data) => {
  editableOrderCode.value = data.code
  editModalIsOpened.value = true
}
const handleCloseEditModal = () => {
  editModalIsOpened.value = false
  editableOrderCode.value = null
}

const handleTableFilterChangeCollection = (value) => {
  filteredInfo.value = {}

  const today = new Date()

  const oneYearAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate())
  const oneWeekAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
  const dateFormatter = new Intl.DateTimeFormat("zh-CN", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  })

  const updatedAtDateRange = [
    dateFormatter.format(oneYearAgo).replaceAll("/", "-"),
    dateFormatter.format(oneWeekAgo).replaceAll("/", "-")
  ]

  filteredInfo.value.status = value
  filteredInfo.value.updated_at = updatedAtDateRange

  handleTableFilterChange()
}

onMounted(() => {
  setupTable({
    defaultSorter: {
      columnKey: "updated_at",
      order: "ascend"
    }
  })
  getMeta()
  onTableChange()
})
</script>
