<template>
  <a-table
    :dataSource="dataSource"
    :loading="isFetching"
    rowKey="id"
    :columns="columns"
    :pagination="paginationInfo"
    style="padding: 0 32px"
    @change="onTableChange"
  >
    <template #dateRenderer="value">
      <TableRendererDateTime :date="value" />
    </template>

    <template #operation="text">
      {{ actionType(text).value }}
    </template>

    <template #description="text">
      <a-popover v-if="text">
        <template #content>
          <span v-if="text.ru">{{ text.ru }}</span>
          <span v-else>{{ text }}</span>
        </template>
        <a-button
          size="small"
          shape="circle"
          type="dashed"
        >
          <a-icon type="info" />
        </a-button>
      </a-popover>
    </template>

    <template #amount="value, record">
      <span :class="{ 'negative-sum-operation': checkMinusOperation(value, record) }">
        {{ value }}
      </span>
    </template>
  </a-table>
</template>

<script setup>
import { ref, onMounted } from "vue"
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime/"

import { fetchSellerOperations, getSellersMeta } from "@/modules/MPAdmin/services/billingService.js"
import useMPAConstants from "@/modules/MPAdmin/constants.js"

import useBillingPageColumns from "@/modules/MPAdmin/pages/Billing/useBillingPageColumns.js"
import useAntTableQuery from "@/composables/useAntTableQuery.js"

const props = defineProps({
  sellerUuid: {
    type: String,
    required: true
  }
})

const {
  dataSource,
  isFetching,

  paginationInfo,

  fetchTableInfo
} = useAntTableQuery({
  queryFunction: fetchSellerOperations,
  requestParams: { seller_id: props.sellerUuid }
})
const { negativeOperationTypes } = useMPAConstants()
const { billingHistoryColumns: columns } = useBillingPageColumns()

const operationTypes = ref([])

const onTableChange = (pagination = paginationInfo.value) => {
  fetchTableInfo({ pagination })
}

const getMeta = async () => {
  try {
    const { data } = await getSellersMeta()
    operationTypes.value = data.action_types
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) return

    setTimeout(getMeta, 2500)
  }
}

const checkMinusOperation = (value, row) => {
  return negativeOperationTypes.includes(row.type) && value !== 0
}

const actionType = (value) => {
  if (!operationTypes.value.length) return ""
  return operationTypes.value.find((item) => item.key === value)
}
onMounted(() => {
  getMeta()
  onTableChange()
})
</script>

<style scoped lang="scss">
.negative-sum-operation {
  color: $red-color;

  &::before {
    content: "-";
  }
}
</style>
