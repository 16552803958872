<template>
  <div class="auth_form">
    <a-form-model
      ref="formRef"
      :model="formModel"
      :rules="loginRules"
      class="auth_form__form"
      hideRequiredMark
      @submit.prevent.stop="handleLogin()"
    >
      <div class="header">
        <h1>{{ $t("synthetic") }}</h1>
        <h2>{{ $t("authorization") }}</h2>
      </div>

      <div class="auth_content">
        <a-form-model-item
          prop="username"
          :label="$t('login')"
        >
          <a-input
            v-model="formModel.username"
            name="login"
            size="large"
          />
        </a-form-model-item>

        <a-form-model-item
          prop="password"
          :label="$t('password')"
        >
          <a-input
            v-model="formModel.password"
            size="large"
            name="password"
            type="password"
          />
        </a-form-model-item>

        <a-form-item validateStatus="error">
          <a-button
            type="primary"
            size="large"
            block
            :loading="loginRequest || isSubmiting"
            html-type="submit"
          >
            {{ $t("signIn") }}
          </a-button>
        </a-form-item>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import i18n from "@/i18n"
import { computed, reactive, ref } from "vue"
import { mapActions, mapState } from "vuex"

export default {
  name: "Login",
  setup() {
    const formRef = ref()

    const formModel = reactive({
      username: "",
      password: ""
    })
    const loginRules = {
      username: [{ required: true, message: i18n.t("emptyError") }],
      password: [{ required: true, message: i18n.t("emptyError") }]
    }

    const isSubmiting = ref(false)

    const isValid = computed(() => {
      const { username, password } = formModel
      return username.length > 0 && password.length > 0
    })

    return {
      formRef,
      formModel,
      loginRules,

      isSubmiting,

      isValid
    }
  },

  computed: {
    ...mapState("Auth", {
      isLogged: (state) => state.isLogged,
      loginRequest: (state) => state.loginRequest
    })
  },

  mounted() {
    if (this.isLogged) {
      this.$router.push({ path: "/mp-admin/categories/" })
    }
  },

  methods: {
    ...mapActions("Auth", ["login"]),

    async handleLogin() {
      this.isSubmiting = true
      try {
        await this.formRef.validate()

        await this.login(this.formModel)
      } catch (error) {
        this.$message.error(this.$t("wrongLoginOrPassword"))
      } finally {
        this.isSubmiting = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.auth_form {
  min-width: 100%;
  height: calc(100vh - 125px);
  display: flex;
  align-items: center;
  justify-content: center;

  & .header {
    padding: 0 20px;
    text-align: center;
    color: #555;
  }

  & .auth_content {
    border: 1px solid $border-color;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: $element-shadow;
    padding: 15px 20px;
  }

  &__section {
    padding: 5px 0;
  }

  &__footer {
    padding: 10px 0 0 0;
    margin-top: 10px;
    border-top: 1px solid $border-color;
    font-size: 13.33px;
    color: $dark;
    text-align: center;

    & a {
      font-size: 13.33px;
    }
  }

  &__form {
    width: 320px;
    height: auto;
    margin: auto;
  }
}
</style>
