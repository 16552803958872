import notifyResponseError from "@/utils/notifyResponseError"
import { transformQuery } from "./servicesUtils"
import { API } from "@/utils/HttpUtils"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"
import Cookies from "js-cookie"

export const fetchTags = ({ queryParams, requestParams, signal }) => {
  return API.get(`/mp-admin/tags${transformQuery(queryParams, requestParams?.changeRouterQuery)}`, {
    signal
  })
}

export const createTag = (data) => {
  return API.post("/mp-admin/tags", data)
}

export const fetchSingleTag = (uuid) => {
  return API.get(`/mp-admin/tags/${uuid}`)
}

export const updateTag = (data, uuid) => {
  return API.patch(`/mp-admin/tags/${uuid}`, data)
}

export const fetchTagOffers = ({ queryParams, requestParams, signal }) => {
  return API.get(
    `/mp-admin/tags/${requestParams.uuid}/offers${transformQuery(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    { signal }
  )
}

export const addOffersToTag = (uuid, csCodes) => {
  return API.post(`/mp-admin/tags/${uuid}/offers/set`, csCodes)
}

export const deleteOffersFromTag = ({ uuid, csCodes }) => {
  return API.post(`/mp-admin/tags/${uuid}/offers/clean`, csCodes)
}

export const editTagOffers = (uuid, csCodes) => {
  return API.post(`/mp-admin/tags/${uuid}/offers/update`, csCodes)
}

export const editTagActivity = (data, uuid) => {
  return API.put(`/mp-admin/tags/${uuid}`, data)
}

export const importTagsFile = (file) => {
  const payload = new FormData()
  payload.append("file", file)

  return API.post(`/mp-admin/tags/import`, payload)
}

export const checkTagsImportTask = async (task_id) => {
  try {
    const { data } = await API.get(`/mp-admin/tags/import-status/${task_id}`)
    if (data.state === "PENDING") return setTimeout(checkTagsImportTask, 2500, task_id)
    if (data.state === "SUCCESS") {
      notification.success({ message: i18n.t("tagsImportFinished"), duration: 0 })

      const link = document.createElement("a")
      link.href = data.url
      link.setAttribute("download", "tag-import-results.csv")
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
    }
  } catch (error) {
    notifyResponseError({ error })
  }
}

export const importOffersTagsFile = (file) => {
  const payload = new FormData()
  payload.append("file", file)

  return API.post(`/mp-admin/tags/import/offers/import`, payload)
}

export const checkOffersTagsImportTask = async (task_id) => {
  try {
    const { data } = await API.get(`/mp-admin/tags/import/offers/import-status/${task_id}`)

    if (data.state === "PENDING") return setTimeout(checkTagsImportTask, 2500, task_id)
    if (data.state === "SUCCESS") {
      notification.success({ message: i18n.t("offersImportFinished"), duration: 0 })

      const link = document.createElement("a")
      link.href = data.url
      link.setAttribute("download", "tag-import-results.csv")
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
    }
  } catch (error) {
    notifyResponseError({ error })
  }
}

export const getCategoriesTree = () => {
  return API.get("mp-admin/categories/tree")
}

export const checkSessionCookieExpired = () => {
  return !Boolean(Cookies.get("sessionid"))
}
