import i18n from "@/i18n"

export const hiddenMenuItems = ["00B2b", "FIN", "00SALE", "00MPS", "00SUP", "00SER"]

export const pathForComponents = {
  // User management
  USRCMP: "/users-management/users",
  RLSCMP: "/users-management/roles",

  // General marketplace
  X02MPA: "/mp-admin/general-settings",
  X10MPA: "/mp-admin/pages",
  BS01: "/mp-admin/bonuses",
  X04MPA: "/mp-admin/promotions",
  X11MPA: "/mp-admin/customers",
  BL01: "/mp-admin/billing",
  LOC01: "/mp-admin/locations",
  POS01: "/mp-admin/post-offices",

  // Catalog marketplace
  X01MPA: "/mp-admin/categories",
  X03MPA: "/mp-admin/moderation-products",
  X05MPA: "/mp-admin/moderation-propositions",
  SKD01: "/mp-admin/search-keywords",
  TAG01: "/mp-admin/offer-tags",
  SYNC01: "/mp-admin/synchronizations",

  // Reviews
  X07MPA: "/mp-admin/products-reviews",
  X08MPA: "/mp-admin/sellers-reviews",

  // Sells marketplace
  X06MPA: "/mp-admin/moderation-orders",
  RT01: "/mp-admin/returns",
  "00SLS": "/mp-admin/sellers",
  INV01: "/mp-admin/invoices",
  MS02: "/mp-admin/messenger-moderation",

  // Product catalog
  PRODCAT02: "/moderation/products",
  "PM:COMP:EX": "/moderation/export",
  "PM:COMP:EH": "/moderation/export-history",
  PRODCAT3: "/product-catalog/brands",
  PRODCAT4: "/product-catalog/types",
  PRODCAT7: "/product-catalog/attributes",
  PRODCAT6: "/product-catalog/values",
  PRODCAT5: "/product-catalog/units",
  PRODCAT81: "/product-catalog/attributes-edit",

  // ADS
  M01MERCH: "/ads/merchant",
  M01SELL: "/ads/sellers",
  M01BLCL: "/ads/blacklists",
  M01CPA: "/ads/orders",
  M02CPC: "/ads/campaigns",
  M02REF: "/ads/referrers"
}

export const usersManagementComponents = [
  {
    permissionCode: "UMGMT/USRCMP",
    code: "USRCMP",
    title: i18n.t("users")
  },
  {
    permissionCode: "UMGMT/RLSCMP",
    code: "RLSCMP",
    title: i18n.t("roles")
  }
]

export const generalMarketplaceComponents = [
  {
    permissionCode: "00MPA/X02MPA",
    code: "X02MPA",
    title: i18n.t("generalSettings")
  },
  {
    permissionCode: "00MPA/X10MPA",
    code: "X10MPA",
    title: i18n.t("pageBuilder")
  },
  {
    permissionCode: "00MPA/BS01",
    code: "BS01",
    title: i18n.t("bonusTitle")
  },
  {
    permissionCode: "00MPA/X04MPA",
    code: "X04MPA",
    title: i18n.t("advertisingOptions")
  },
  {
    permissionCode: "00MPA/X11MPA",
    code: "X11MPA",
    title: i18n.t("moderationBuyers")
  },
  {
    permissionCode: "00MPA/BL01",
    code: "BL01",
    title: i18n.t("billing")
  },
  {
    permissionCode: "00MPA/LOC01",
    code: "LOC01",
    title: i18n.t("locationsSettings")
  },
  {
    permissionCode: "00MPA/POS01",
    code: "POS01",
    title: i18n.t("postOffices")
  }
]

export const catalogMarketplaceComponents = [
  {
    permissionCode: "00MPA/X01MPA",
    code: "X01MPA",
    title: i18n.t("categories")
  },
  {
    permissionCode: "00MPA/X03MPA",
    code: "X03MPA",
    title: i18n.t("products")
  },
  {
    permissionCode: "00MPA/X05MPA",
    code: "X05MPA",
    title: i18n.t("offerCount")
  },
  {
    permissionCode: "00MPA/TAG01",
    code: "TAG01",
    title: i18n.t("offerTags")
  },
  {
    permissionCode: "00MPA/SYNC01",
    code: "SYNC01",
    title: i18n.t("synchronizations")
  },
  {
    permissionCode: "00MPA/SKD01",
    code: "SKD01",
    title: i18n.t("searchSettings")
  }
]

export const reviewsComponents = [
  {
    permissionCode: "00MPA/X07MPA",
    code: "X07MPA",
    title: i18n.t("productsReviews")
  },
  {
    permissionCode: "00MPA/X08MPA",
    code: "X08MPA",
    title: i18n.t("sellersReviews")
  }
]

export const sellsMarketplaceComponents = [
  {
    permissionCode: "00MPA/X06MPA",
    code: "X06MPA",
    title: i18n.t("orders")
  },
  {
    permissionCode: "00MPA/RT01",
    code: "RT01",
    title: i18n.t("ordersReturns")
  },
  {
    permissionCode: "00MPA/00SLS",
    code: "00SLS",
    title: i18n.t("sellers")
  },
  {
    permissionCode: "00MPA/INV01",
    code: "INV01",
    title: i18n.t("sellerAccounts")
  },
  {
    permissionCode: "00MPA/MS02",
    code: "MS02",
    title: i18n.t("messengerModeration")
  }
]

export const productCatalogComponents = [
  {
    permissionCode: `PRODCAT1/PRODCAT02`,
    code: "PRODCAT02",
    title: i18n.t("moderationOfProducts")
  },
  {
    permissionCode: `PRODCAT1/PM:COMP:EX`,
    code: "PM:COMP:EX",
    title: i18n.t("export")
  },
  {
    permissionCode: `PRODCAT1/PM:COMP:EH`,
    code: "PM:COMP:EH",
    title: i18n.t("exportHistoryTitle")
  },
  {
    permissionCode: `PRODCAT1/PRODCAT3`,
    code: "PRODCAT3",
    title: i18n.t("brands")
  },
  {
    permissionCode: `PRODCAT1/PRODCAT4`,
    code: "PRODCAT4",
    title: i18n.t("types")
  },
  {
    permissionCode: `PRODCAT1/PRODCAT7`,
    code: "PRODCAT7",
    title: i18n.t("characteristics")
  },
  {
    permissionCode: `PRODCAT1/PRODCAT6`,
    code: "PRODCAT6",
    title: i18n.t("listValues")
  },
  {
    permissionCode: `PRODCAT1/PRODCAT5`,
    code: "PRODCAT5",
    title: i18n.t("units")
  },
  {
    permissionCode: `PRODCAT1/PRODCAT81`,
    code: "PRODCAT81",
    title: i18n.t("valueProcessing")
  }
]

export const adsComponents = [
  {
    permissionCode: "00ADS/M01MERCH",
    code: "M01MERCH",
    title: "Merchant"
  },
  {
    permissionCode: "00ADS/M01SELL",
    code: "M01SELL",
    title: i18n.t("sellers")
  },
  {
    permissionCode: "00ADS/M01BLCL",
    code: "M01BLCL",
    title: i18n.t("blackList")
  },
  {
    permissionCode: "00ADS/M01CPA",
    code: "M01CPA",
    title: "CPA"
  },
  {
    permissionCode: "00ADS/M02CPC",
    code: "M02CPC",
    title: "CPC"
  },
  {
    permissionCode: "00ADS/M02REF",
    code: "M02REF",
    title: i18n.t("referrers")
  }
]

export const commonAplications = [
  {
    applicationCode: "UMGMT",
    components: usersManagementComponents,
    title: i18n.t("usersManagement"),
    key: "UserManagement"
  },
  {
    applicationCode: "00MPA",
    components: generalMarketplaceComponents,
    title: i18n.t("generalMP"),
    key: "General"
  },
  {
    applicationCode: "00MPA",
    components: catalogMarketplaceComponents,
    title: i18n.t("catalogMP"),
    key: "Catalog"
  },

  {
    applicationCode: "00MPA",
    components: reviewsComponents,
    title: i18n.t("reviewsMP"),
    key: "Reviews"
  },
  {
    applicationCode: "00MPA",
    components: sellsMarketplaceComponents,
    title: i18n.t("sellsMP"),
    key: "Sells"
  },
  {
    applicationCode: "PRODCAT1",
    components: productCatalogComponents,
    title: i18n.t("productCatalog"),
    key: "ProductCatalog"
  }
]
