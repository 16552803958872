<template>
  <a-form-model class="calculate-modal">
    <div class="calculate-modal__search">
      <a-form-model-item
        :label="$t('enterSearchQuery')"
        :colon="false"
      >
        <a-input
          v-model="form.q"
          allowClear
          @pressEnter="handleSearch()"
          @change="onFormChange()"
        />
      </a-form-model-item>

      <a-form-model-item
        label=" "
        :colon="false"
        layout="inline"
      >
        <a-checkbox
          v-model="form.in_stock"
          @change="onStockChange()"
        >
          {{ $t("inStock") }}
        </a-checkbox>
      </a-form-model-item>

      <a-form-model-item
        label=" "
        :colon="false"
      >
        <a-button
          type="dashed"
          :disabled="!form.q"
          :loading="isFetching"
          @click="handleSearch()"
        >
          {{ $t("calculate") }}
        </a-button>
      </a-form-model-item>
    </div>

    <div style="display: flex; align-items: center; gap: 6px">
      {{ $t("finded") }}: {{ paginationInfo.total }}
      <a-popover v-if="paginationInfo.total">
        <template #content> {{ $t("minKeywordsScore") }}: {{ minScore }} </template>
        <a-icon
          type="info-circle"
          theme="twoTone"
          style="font-size: 16px"
        />
      </a-popover>
    </div>

    <a-list
      :pagination="dataSource.length ? paginationInfo : false"
      :loading="isFetching"
    >
      <a-list-item
        v-for="(item, index) in dataSource"
        :key="`${item.p_code}__${index}`"
      >
        <div class="calculate-modal__product">
          <a-avatar
            v-if="item.images?.length"
            shape="square"
            :size="60"
            :src="getFullImagePath(item.images[0])"
          />

          <div class="calculate-modal__product__info">
            <a-button
              type="link"
              icon="link"
              style="padding: 0"
              :href="getProductLink(item)"
              target="_blank"
            >
              {{ item.p_code }}
            </a-button>

            {{ item.name }}
          </div>
        </div>

        <template #actions>
          <div style="display: flex; gap: 16px; align-items: center">
            <a-alert
              v-if="item.filter_score < minScore"
              :message="$t('productWontShowInSample')"
              type="warning"
              showIcon
            />

            <a-tooltip :title="$t('filterSortedScoreHelp')">
              <a-tag style="margin: 0">
                {{ getFormatedSum(item.filter_score, true) }} /
                {{ getFormatedSum(item.sorted_score, true) }}
              </a-tag>
            </a-tooltip>

            <a-button
              type="dashed"
              shape="circle"
              icon="edit"
              @click="goToProductKeywords(item)"
            />
          </div>
        </template>
      </a-list-item>
    </a-list>

    <div class="drawer-footer">
      <a-button @click="closeModal()">
        {{ $t("cancel") }}
      </a-button>
    </div>

    <a-drawer
      :visible="showEditModal"
      :width="1000"
      destroyOnClose
      @close="onHideEditModal()"
    >
      <template #title>
        <span>
          {{ modalTitle?.main }}
        </span>
        <span style="opacity: 0.5">
          {{ modalTitle?.secondary }}
        </span>
      </template>
      <EditSearchModal
        :chosenSearch="chosenSearch"
        @submit="onSearchChanged"
        @close="onHideEditModal()"
      />
    </a-drawer>
  </a-form-model>
</template>

<script setup>
import { ref, computed, reactive } from "vue"
import {
  fetchSearchById,
  fetchKeywordProducts,
  checkProductKeywordScore
} from "@/modules/MPAdmin/services/searchKeywordsService.js"
import getImagePath from "@/utils/getImagePath.js"
import { IMAGES_CDN, MARKETPLACE } from "@/constants/common.js"
import getFormatedSum from "@/utils/getFormatedSum"
import notifyResponseError from "@/utils/notifyResponseError"
import getCurrentLocale from "@/utils/getCurrentLocale.js"
import { getFormatedDateTime } from "@/utils/getFormatedDateTime.js"
import { EditSearchModal } from "./index.js"

const emit = defineEmits(["editKeywords"])

const form = reactive({
  q: "",
  in_stock: false
})
const dataSource = ref([])
const isFetching = ref(false)
const minScore = ref(0)
const showEditModal = ref(false)
const chosenSearch = ref()

const onPaginationChange = (current, pageSize) => {
  paginationInfo.value = { ...paginationInfo.value, current, pageSize }

  handleSearch()
}

const paginationInfo = ref({
  current: 1,
  pageSize: 10,
  total: 0,
  showSizeChanger: true,
  showQuickJumper: false,
  pageSizeOptions: ["10", "25", "50", "100"],
  onChange: onPaginationChange,
  onShowSizeChange: onPaginationChange
})

const modalTitle = computed(() => {
  if (!chosenSearch.value) return ""
  const { product } = chosenSearch.value
  const main = `${product?.name ? product.name[getCurrentLocale()] : ""} ${product?.p_code || ""} ${
    product?.category?.name ? product.category.name[getCurrentLocale()] : ""
  }`

  return {
    main: main.length > 75 ? `${main.slice(0, 75)}...` : main,
    fullMain: main,
    secondary: getFormatedDateTime(product.created_at)
  }
})

const onFormChange = () => {
  paginationInfo.value = { ...paginationInfo.value, current: 1, total: 0 }
  dataSource.value = []
  minScore.value = 0
}

const onStockChange = () => {
  onFormChange()
  handleSearch()
}

const handleSearch = async () => {
  if (!form.q) return

  try {
    isFetching.value = true
    const queryParams = {
      q: form.q,
      pageSize: paginationInfo.value.pageSize,
      page: paginationInfo.value.current
    }
    if (form.in_stock) {
      queryParams.in_stock = true
    }

    const { data } = await fetchKeywordProducts({
      queryParams
    })
    dataSource.value = data.results
    minScore.value = data.meta.min_score
    paginationInfo.value.total = data.count
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const goToProductKeywords = async (product) => {
  try {
    const { data } = await fetchSearchById({ uuid: product.keywords_uuid })
    if (!data) return

    onShowEditModal(data)
  } catch (error) {
    notifyResponseError({ error })
  }
}

const getFullImagePath = (path) => `${IMAGES_CDN}/media/assets/images/${getImagePath(path, "full")}`

const getProductLink = (product) => {
  return `${MARKETPLACE}/product/${product.slug}/${product.p_code}/${product.cs_code}`
}

const onShowEditModal = (record) => {
  chosenSearch.value = record
  showEditModal.value = true
}

const onHideEditModal = () => {
  showEditModal.value = false
  chosenSearch.value = undefined
}

const onSearchChanged = async (payload) => {
  try {
    const { data } = await checkProductKeywordScore({
      queryParams: form,
      code: payload.product.p_code
    })

    const productIndex = dataSource.value.findIndex(
      ({ keywords_uuid }) => keywords_uuid === chosenSearch.value.uuid
    )
    dataSource.value[productIndex] = { ...dataSource.value[productIndex], score: data.score || 0 }
    onHideEditModal()
  } catch (error) {
    notifyResponseError({ error })
  }
}

const closeModal = () => {
  emit("close")
}
</script>

<style lang="scss" scoped>
.calculate-modal {
  padding-bottom: 50px;
  color: $font-default;

  &__search {
    width: 100%;
    display: flex;
    gap: 16px;
    justify-content: space-between;

    & > * {
      flex: 2;
    }

    & > :first-child {
      flex: 8;
    }

    & > :last-child {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }

  &__product {
    display: flex;
    flex-flow: row nowrap;
    gap: 12px;

    &__info {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;

      color: $font-default;
    }
  }
}
</style>

<style>
.calculate-modal img {
  object-fit: contain;
}
</style>
