export default {
  tagsList: "Список тегів",
  createTag: "Створити тег",
  tagRating: "Рейтинг",
  tagRatingTip: "Товари на сторінці тегу показуються від більшого до меншого рейтингу",
  tagRatingModalTip: ", перший CS-code буде останнім на сторінці тегу",
  tagRatingModalTipSameOrder: "CS-code відсортуються відповідно до факторів рейтингу P-коду",
  tagName: "Назва тегу",
  slug: "Слаг",
  heading1: "H1",
  thisFieldMustBeFilled: "Це поле має бути заповненим",
  titleIsIncorrect: "Назва заповнена неправильно",
  slugIsIncorrect: "Slug тегу некоректний",
  offerQuantity: "Кількість оферів",
  tagEditing: "Редагування тегу",
  settings: "Налаштування",
  addNewCodes: "Додати нові CS-коди",
  codesAddedSuccessfully: "CS-коди успішно додані",
  tagActivity: "Активність тегу",
  noteFromManager: "Нотатка від Менеджера",
  productInformation: "Інформація про товар",
  detailsFromSeller: "Деталі від продавця",
  priceNew: "Нова ціна",
  sequenceNumber: "Порядковий номер",
  massiveInput: "Масове введення",
  inAscendingOrder: "В порядку зростання",
  sameForAll: "Однаковий усім",
  startFrom: "Розпочати з",
  changeSequenceNumber: "Змінити порядковий номер",
  tag: {
    title: "Назва тегу",
    slug: "Slug",
    seo_h1: "H1",
    _note: "Нотатка"
  },
  confirmToDeleteOffers: "Видалити обрані пропозиції?",
  massiveCsCodesHelp: "Перелік CS-кодів через кому, пробіл або Enter",
  seoBlankError: "Усі SEO поля мають бути заповнені для активації тегу",
  codesNotFound: "Помилка при масовому введені, деякі CS коди не знайдено.",
  maxNoteError: "Максимальна довжина нотатки: ",
  tagActivityChanged: "Активність тегу успішно змінена",
  noOffersInTagError: "Тег має містити хоча б 1 пропозицію",
  changeTagRating: "Змінити рейтинг",
  fillNameAndSlug: 'Заповніть поля "Назва" та "Слаг"',
  importTags: "Імпорт тегів",
  importTagOffers: "Імпорт пропозицій",
  importTagsFile: "Імпорт тегів з файлу",
  importOffersFile: "Імпорт пропозицій з файлу",
  downloadTagsImportExample: "Скачати приклад файлу імпорту",
  downloadExample: "Завантажити приклад",
  tagsImportStarted: "Імпорт тегів стартував",
  offersImportStarted: "Імпорт пропозицій стартував",
  tagsImportFinished: "Імпорт тегів успішно завершено",
  offersImportFinished: "Імпорт пропозицій успішно завершено"
}
