<template>
  <div>
    <PageHeader :title="$t('sellersReviews')">
      <template #filters>
        <a-range-picker
          v-model="filteredInfo.created_at"
          allowClear
          format="DD.MM.YYYY"
          valueFormat="YYYY-MM-DD"
          @change="handleTableFilterChange()"
        />

        <a-input-group compact>
          <a-input-number
            :placeholder="`${i18n.t('user')} (id)`"
            v-model="filteredInfo.author_id"
            style="width: calc(100% - 32px)"
          />
          <a-button
            icon="search"
            style="width: 32px"
            @click="handleTableFilterChange()"
          />
        </a-input-group>

        <a-input-search
          v-for="field in searchFields"
          :key="field.key"
          v-model="filteredInfo[field.key]"
          allowClear
          :placeholder="field.label"
          @search="handleTableFilterChange()"
        >
          <template #enterButton>
            <a-button icon="search" />
          </template>
        </a-input-search>

        <a-select
          v-model="filteredInfo.rating_product_overall"
          :options="ratingOptions"
          allowClear
          :placeholder="$t('ratingOverall')"
          @change="handleTableFilterChange()"
        />
      </template>

      <template #actions>
        <div class="table-header__actions">
          <a-button
            icon="undo"
            @click="handleTableFiltersReset()"
          >
            {{ $t("clearFilters") }}
          </a-button>

          <a-select
            v-model="filteredInfo.moderation_status"
            :options="moderationStatusOptions"
            :placeholder="$t('moderationStatus')"
            size="large"
            style="width: 200px"
            allowClear
            @change="handleTableFilterChange()"
          />
        </div>
      </template>
    </PageHeader>

    <a-table
      :dataSource="dataSource"
      :loading="isFetching"
      :columns="columns"
      :pagination="paginationInfo"
      rowKey="id"
      :scroll="{ x: true }"
      @change="onTableChange"
    >
      <template #dateRenderer="value">
        <TableRendererDateTime :date="value" />
      </template>

      <template #sellerRenderer="seller, record">
        <a-button
          type="link"
          style="padding: 0"
          :href="sellerUrl(record?.order?.seller?.slug)"
          target="_blank"
        >
          {{ seller }}
        </a-button>
      </template>

      <template
        #rating="{
          rating_product_overall,
          rating_product_description_fits,
          rating_delivery,
          rating_service
        }"
      >
        <a-popover style="cursor: help">
          <div :style="{ color: ratingOptions[rating_product_overall].color }">
            <a-icon :type="ratingOptions[rating_product_overall].icon" />
            {{ ratingOptions[rating_product_overall].label }}
          </div>
          <template #content>
            <div>
              {{ $t("rating_product_description_fits") }}
            </div>
            <a-rate
              disabled
              :value="rating_product_description_fits"
            />

            <div>
              {{ $t("rating_delivery") }}
            </div>
            <a-rate
              disabled
              :value="rating_delivery"
            />

            <div>
              {{ $t("rating_service") }}
            </div>
            <a-rate
              disabled
              :value="rating_service"
            />
          </template>
          ({{ rating_product_description_fits }}/{{ rating_delivery }}/{{ rating_service }})
        </a-popover>
      </template>

      <template #fullname="{ author }">
        <b>{{ author?.username }}</b>
        <div>{{ author.first_name }} {{ author.middle_name }} {{ author.last_name }}</div>
      </template>

      <template #contactsRenderer="author">
        <UserContacts
          :email="author.email"
          :mobilePhone="author.mobile_phone"
        />
      </template>

      <template #comment="value">
        <a-popover placement="topLeft">
          <template #content>
            <div style="max-width: 450px">
              {{ value }}
            </div>
          </template>

          <div class="clamped-comment">{{ value }}</div>
        </a-popover>
      </template>

      <template #statusRender="value">
        <ModerationStatus :status="Object.keys(value)[0]" />
      </template>

      <template #images="record">
        <TableRendererPhotoGallery
          v-if="record?.images?.length"
          :images="record.images"
        />
      </template>

      <template #orderRenderer="code">
        <a-button
          type="link"
          style="padding: 0"
          @click="goToOrder(code)"
        >
          {{ code }}
        </a-button>
      </template>

      <template #parent="{ parent }">
        <a-button
          v-if="typeof parent === 'number'"
          type="dashed"
          shape="circle"
          icon="eye"
          @click="handleShowParentModal(parent)"
        />
      </template>

      <template #actions="record">
        <a-button
          type="dashed"
          shape="circle"
          icon="edit"
          @click="openReviewModal(record)"
        />
      </template>
    </a-table>

    <a-drawer
      :visible="showEditReview"
      :width="700"
      destroyOnClose
      @close="closeReviewModal"
    >
      <EditSellerReview
        :prototype="selectedReview"
        :ratingOptions="ratingOptions"
        :moderationStatusOptions="moderationStatusOptions"
        @updateReview="onUpdateReview"
      />
    </a-drawer>

    <a-drawer
      :width="600"
      :visible="showParentReviewModal"
      :title="$t('parentReview')"
      destroyOnClose
      @close="showParentReviewModal = false"
    >
      <ParentReviewModal
        :parent="parentReview"
        :ratingOptions="ratingOptions"
        @close="showParentReviewModal = false"
      />
    </a-drawer>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue"
import i18n from "@/i18n"
import TableRendererPhotoGallery from "@/ant-components/renderers/TableRendererPhotoGallery/"
import ModerationStatus from "../renderers/ModerationStatus.vue"

import ParentReviewModal from "./components/ParentReviewModal.vue"
import EditSellerReview from "./components/EditSellerReview.vue"
import UserContacts from "../renderers/UserContacts.vue"
import TableRendererDateTime from "@/ant-components/renderers/TableRendererDateTime"

import {
  fetchSellerReviews,
  fetchSellerReviewsMeta
} from "@/modules/MPAdmin/services/sellerReviewsService.js"

import useSRPageColumns from "./useSRPageColumns"

import { MARKETPLACE } from "@/constants/common"
import useAntTableQuery from "@/composables/useAntTableQuery.js"
import router from "@/router"
import PageHeader from "@/ant-components/PageHeader/PageHeader.vue"

// metaInfo: {
//   title: "Відгуки про продавців"
// },

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  handleTableFilterChange,
  handleTableFiltersReset,
  updateTableDataRecord
} = useAntTableQuery({
  queryFunction: fetchSellerReviews,
  requestParams: { changeRouterQuery: true }
})
const columns = useSRPageColumns({ sortedInfo: computed(() => sortedInfo.value) })

const showEditReview = ref(false)
const selectedReview = ref(undefined)

const showParentReviewModal = ref(false)
const parentReview = ref(0)
const moderationStatusOptions = ref([])

const searchFields = computed(() => [
  {
    key: "seller_name",
    label: i18n.t("seller")
  },
  {
    key: "author_fullname",
    label: i18n.t("authorFullname")
  },
  {
    key: "author_contacts",
    label: `${i18n.t("phoneNumber")} / Email`
  },
  {
    key: "comment",
    label: i18n.t("comment")
  },
  {
    key: "order_code",
    label: i18n.t("orderNumber")
  }
])
const ratingOptions = computed(() => [
  {
    label: i18n.t("bad"),
    icon: "frown",
    color: "#DE1C24",
    value: 0
  },
  {
    label: i18n.t("norm"),
    icon: "meh",
    color: "#8A8A8A",
    value: 1
  },
  {
    label: i18n.t("good"),
    icon: "smile",
    color: "#009939",
    value: 2
  }
])

const getMeta = async () => {
  try {
    const { data } = await fetchSellerReviewsMeta()

    moderationStatusOptions.value = data.moderation_statuses.map((status) => ({
      value: Object.keys(status)[0],
      label: i18n.t(Object.keys(status)[0])
    }))
  } catch (error) {
    if (error?.response?.status === 401 || error?.response?.status === 403) return
    setTimeout(getMeta, 2500)
  }
}

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}

const handleShowParentModal = (parent = null) => {
  showParentReviewModal.value = !showParentReviewModal.value
  parentReview.value = parent
}

const openReviewModal = (review) => {
  selectedReview.value = review
  showEditReview.value = true
}

const closeReviewModal = () => {
  showEditReview.value = false
  selectedReview.value = undefined
}

const onUpdateReview = (payload) => {
  updateTableDataRecord({ payload, identifier: "id" })
  closeReviewModal()
}

const goToOrder = (code) => {
  const route = router.resolve({
    name: "Orders",
    query: {
      code
    }
  })
  window.open(route.href, "_blank")
}

const sellerUrl = (slug) => `${MARKETPLACE}/seller/${slug}`

onMounted(() => {
  setupTable()
  getMeta()
  onTableChange()
})
</script>

<style lang="scss" scoped>
.table-header__actions {
  display: flex;
  flex-flow: column nowrap;
  gap: inherit;
}

.clamped-comment {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  cursor: help;
}
</style>
