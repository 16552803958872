import { computed } from "vue"
import i18n from "@/i18n"

export default () => {
  const sequenceNumberOrderOptions = computed(() => [
    {
      label: i18n.t("inAscendingOrder"),
      value: "ascending"
    },
    {
      label: i18n.t("sameForAll"),
      value: "same"
    }
  ])

  const tagsImportCsvContent = [
    [
      "tag_name",
      "seo_h1_uk",
      "seo_h1_ru",
      "seo_h1_en",
      "seo_title_uk",
      "seo_title_ru",
      "seo_title_en",
      "seo_description_uk",
      "seo_description_ru",
      "seo_description_en",
      "seo_text_uk",
      "seo_text_ru",
      "seo_text_en",
      "is_active",
      "slug"
    ],
    [
      "Name",
      "Заголовок",
      "Заголовок",
      "Heading",
      "Назва",
      "Название",
      "Title",
      "Опис",
      "Описание",
      "Description",
      "Текст",
      "Текст",
      "Text",
      "False",
      "tag-slug"
    ]
  ]
    .map((e) => e.join(","))
    .join("\n")

  const offersTagsImportCsvContent = [
    ["tag_slug", "cs_code", "position"],
    ["tag-slug", "CSXXXXXXX", "0"],
    ["tag-slug-2", "CSYYYYYYY", "1"]
  ]
    .map((e) => e.join(","))
    .join("\n")

  return {
    sequenceNumberOrderOptions,
    tagsImportCsvContent,
    offersTagsImportCsvContent
  }
}
